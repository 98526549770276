<template>
  <div class="d-flex flex-row flex-wrap">
    <v-img
      v-for="(img, i) in images"
      :key="'img-' + i"
      :alt="img.alt"
      :src="img.src"
      class="ma-2 selectable"
      max-height="100"
      max-width="100"
      @click="selectImage(img)"
    />
  </div>
</template>

<script>
  /**
   * Example of a custom Image selector
   * Key is to emit a select-file event when a file needs to be added
   */
//   import { VImg } from 'vuetify/lib'
  export default {
    name: "FileSelector",
    // components: { VImg },
    data() {
          return {
        images: [
          { src: 'https://www.apexinfotechindia.com/images/services/digital/social-media-marketing.png', alt: 'Siberian Chipmunk' },
          { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTrDOdhJG6C9kcRTK7fMmSgkX1mlknpsmwTA&usqp=CAU', alt: 'NASA Mars Rover' },
          { src: 'https://storage.googleapis.com/gweb-uniblog-publish-prod/images/Entertainment_Blog_Hero_Image.max-1300x1300.jpg', alt: 'Muybridge race horse animated' },
          { src: 'https://leverageedu.com/blog/wp-content/uploads/2019/06/Career-in-Sports-01.png', alt: 'Locomotive TEM2M-063 2006 G2' },
          { src: 'https://scholarlyoa.com/wp-content/uploads/2021/07/payment-processing-1.png', alt: 'ISS March 2009' },
          { src: 'https://tap2pay.me/wp-content/uploads/2019/11/payment-methods-with-circle_23-2147674741-1-750x423@2x.jpg', alt: 'F-18F after launch from USS Abraham Lincoln (CVN-72)' },
          { src: 'https://magecomp.com/blog/wp-content/uploads/2017/03/How-to-Configure-a-Payment-Method-in-Magento-2.png', alt: 'F-18F after launch from USS Abraham Lincoln (CVN-72)' },
        ]
      };
    },
    methods: {
      selectImage(img) {
        this.$emit('select-file', img);
      }
    }
  }
</script>

<style>
  .selectable {
    cursor: pointer;
  }
</style>
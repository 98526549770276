<template>
    <v-card class="mt-10">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation v-if="categories !== null" >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Title</strong></label>
                            <v-text-field
                            v-model="title"
                            :rules="nameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13 ">
                            <label for=""><strong>Category</strong></label>
                            <v-select
                                v-model="category"
                                :items="categories"
                                :rules="[v => !!v || 'Category is required']"
                                item-text="category"
                                item-value="id"
                                required
                                outlined
                                dense
                                class="category-text"
                                ></v-select>
                         </v-col>
                        <v-col cols="12" lg="12" md="12 " sm="12" xs="12"  class="mb-5">
                              <label for=""><strong>Select Image</strong></label>
                               <tiptap-vuetify
                                    v-model="image"
                                    :extensions="imgExtensions"
                                    placeholder="select image"
                                    @keydown="onkeydown"
                                    class="mt-2 editor-class"
                                    />
                        </v-col>
                         <v-col cols="12" lg="12" md="12 " sm="12" xs="12" class="mb-5">
                              <label for=""><strong>Display content</strong></label>
                               <tiptap-vuetify
                                    v-model="snippet"
                                    :extensions="extensions"
                                    placeholder="Enter display text"
                                    @keydown="onkeydown"
                                    class="mt-2 editor-class"
                                    />
                        </v-col>
                        <v-col cols="12" lg="12" md="12 " sm="12" xs="12" >
                              <label for=""><strong>Blog content</strong></label>
                               <tiptap-vuetify
                                    v-model="content"
                                    :extensions="extensions"
                                    placeholder="Write blog content  …"
                                    @keydown="onkeydown"
                                    class="mt-2 editor-class"
                                    />
                                   
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn
                                color="blue darken-1"
                                dark
                                @click="goBack()"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                            :disabled="!valid || loading"
                            :color="`${zamuYellow3}`"
                            class="mr-4 zamu-sub-heading "
                            @click="save"
                            dark
                            >
                            Submit
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import {  mapGetters, mapMutations } from 'vuex';

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Image } from 'tiptap-vuetify'
import FileSelector from './FileSelector';


export default {
    name: 'newBlog',
    components: { TiptapVuetify },
    mixins: [coreMixin, colorMixin],
     data () {
        return {
            formTitle: 'Add New Blog',
             valid: false,
             loading: false,
             sessionData: null,
            title: '',
            notification: '',
            actionClass: '',
            image: '',
            snippet: '',
            imgExtensions: [
                [Image, {
                options: {
                    imageSources: [
                    { component: FileSelector, name: 'File Selector' }
                    ]
                }
                }],
            ],
            extensions: [
                History,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                [Image, {
                    options: {
                        imageSources: [
                        { component: FileSelector, name: 'File Selector' }
                        ]
                    }
                    }],
               
                [Bold, {
                    renderIn: 'toolbar'
                }],
                [Blockquote, {
                    renderIn: 'toolbar',
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                [Link, {
                    renderIn: 'toolbar'
                }],
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            content: '',
            categories: [],
            category: '',

        };
    },
    computed: {
        ...mapGetters(['getSessionData']),
    },
    watch: {
        getSessionData(data) {
            this.sessionData = data;

        },
    },
    async mounted () {
        this.sessionData = this.getSessionData;
        const categoryData = await this.retrieveBlogCategories();
        this.categories = categoryData.data;

    },
    methods: {
    ...mapMutations(['setUpdateStatus']),

        async checkExists() {
             const payload = {
                    title: this.title,
                    category: this.category,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'blog/exists',
                };

                   try {
                    const res = await this.axiosPostRequest(fullPayload);

                    if(res.status !== 200) {
                        this.notification = 'Blog post failed to save. Contact the admin';
                        this.actionClass = 'error';
                        this.loading = false;
                        return true;
                    } else {
                         this.notification  = res.data.length === 0 ? '' : 'This blog post already exists.';
                        this.actionClass =  res.data.length === 0 ? '' :'error';
                         this.loading = res.data.length > 0 ? false : true;

                        return res.data.length > 0;
                    }
                  
                } catch (error) {
                    this.notification = 'Blog post failed to save. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return true;
                }


        },
        async save () {
            const formvalid = this.$refs.form.validate();
            if(!formvalid) {
                return;
            }

              this.loading = true;

               const payload = {
                    title: this.title,
                    content: this.content,
                    snippet: this.snippet,
                    image: this.image,
                    author: typeof this.sessionData !== 'undefined' ? this.sessionData.id : null,
                    category: this.category,
                    appID: 1,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'blog/new',
                };

               const exists =  await this.checkExists();

               if(exists) {
                   return;
               }

                  try {
                    const res = await this.axiosPostRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.status === 200 ? 'Blog post saved successful' : res.data.message;
                    this.actionClass = res.status === 200 ? 'success' : 'error';
                    if(res.status === 200) {
                        this.setUpdateStatus(true);
                        this.goBack();

                    }

                } catch (error) {
                    this.notification = 'Blog post failed to save. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }


        },
         onkeydown (event) {
          return event;
         },
    }


}
</script>

<style>
 .v-tabs-bar__content > a:first-child { 
      background: red  !important;
  }
  .v-slide-group__content .v-tabs-bar__content {
      display: none !important;

  }

</style>
<style>
.menuable__content__active .v-list-item__content {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87) !important;
}
</style>